import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n, { propTypes as i18nPropTypes, translate } from 'i18n'
import { Field, FieldArray } from 'redux-form'
import { get } from 'lodash'
import { Prompt } from 'react-router'

import { upsertReference, deleteReference, duplicateReference } from 'actions/references'
import { showModal } from 'actions/modal'
import { goBack } from 'connected-react-router'

import AutoSaveButton from 'components/common/AutoSaveButton'
import { OutlinedButton } from 'components/common/Button'
import DuplicateButton from 'components/common/DuplicateButton'

import FontIcon from 'components/common/FontIcon'
import ReferenceFileField from 'components/references/ReferenceFileField'
import DeleteReferenceModal from 'containers/modal/DeleteReferenceModal'

import ReferenceAttributeInput, { ReferenceAttributeSelect, ReferenceLinkAttribute, ReferenceExpandableAttributeInput } from 'components/reference-detail/ReferenceAttributeInput'
import RolesFieldArray from 'components/reference-detail/RolesFieldArray'
import PageSection from 'components/page/PageSection'

import AutoSaveForm from 'containers/common/AutoSaveForm'

import { referenceToFormValues, formValuesToReference } from 'helper/formHelper'

import {
  getReferenceFromPath, getReferenceTypes, getValidRoles, getIsExternalLib,
} from 'selectors/references'
import { isReferenceCountPremiumRestricted } from 'selectors/subscriptions'

import './style.less'


const DELETE_MODAL_ID = 'DELETE_REFERENCE_CONFIRMATION_MODAL'
const FIELD_SLICE_FACTOR = 100 / 53

const detailFields = ['title', 'title-short', 'container-title', 'container-title-short', 'volume', 'issued', 'publisher-place',
  'publisher', 'page', 'shortTitle', 'issue', 'ISBN', 'ISSN', 'PMCID', 'PMID', 'DOI', 'URL',
  'archive', 'archive_location', 'source', 'edition', 'section', 'authority', 'chapter-number', 'number', 'references', 'collection-number',
  'collection-title', 'number-of-pages', 'number-of-volumes', 'accessed', 'dimensions', 'medium', 'genre', 'scale', 'submitted',
  'event', 'call-number', 'status', 'version']

const fieldComponents = {
  DOI: ReferenceLinkAttribute,
  URL: ReferenceLinkAttribute,
}

class ReferenceDetailForm extends Component {
  static propTypes = {
    ...i18nPropTypes,
    referenceTypes: PropTypes.array,
    validRoles: PropTypes.array,
    isExternalLib: PropTypes.bool,
    showModal: PropTypes.func,
    goBack: PropTypes.func,
    deleteReference: PropTypes.func,
    isDirty: PropTypes.bool,
  }

  getLabel = name => this.props.t(`references:types.${this.getType()}.${name}`)

  getType = () => get(this.props, 'formValues.type', 'article')

  handleDuplicateReference = async () => {
    const { formValues } = this.props
    await this.props.duplicateReference(formValues)
  }

  handleDelete = () => {
    this.props.showModal(DELETE_MODAL_ID, { data: this.props.formValues })
  }

  handleConfirmDeleteReference = async () => {
    await this.props.deleteReference(this.props.formValues)
    this.props.goBack()
  }

  renderAttribute = (field, props = {}) => (
    <Field
      key={field}
      name={field}
      label={this.getLabel(field)}
      component={fieldComponents[field] || ReferenceAttributeInput}
      placeholder={this.props.t('references.attributePlaceholder')}
      readOnly={this.props.isExternalLib}
      {...props}
    />
  )

  renderLanguage = () => {
    const { formValues, t } = this.props

    const languages = Object.keys(t('common.languages', { returnObjects: true }))

    if (formValues && (
      (!formValues.language && i18n.exists(`references:types.${this.getType()}.language`))
      || languages.includes(formValues.language))) {
      return (
        <Field
          name="language"
          label={this.props.t('common.language')}
          options={languages.map(l => ({ value: l, label: t(`common.languages.${l}`) }))}
          component={ReferenceAttributeSelect}
          readOnly={this.props.isExternalLib}
        />
      )
    }
    return this.renderAttribute('language')
  }

  renderAbstract = () => {
    const translationKey = `references:types.${this.getType()}.abstract`
    return i18n.exists(translationKey) && (
      <Field
        name="abstract"
        label={this.props.t(translationKey)}
        component={ReferenceExpandableAttributeInput}
        readOnly={this.props.isExternalLib}
        placeholder={this.props.t('references.attributePlaceholder')}
      />
    )
  }

  renderAttributes = () => {
    const { t, referenceTypes } = this.props

    const referenceType = this.getType()
    const mappedFields = Object.keys(t(`references:types.${referenceType}`, { returnObjects: true }))

    const fields = detailFields.filter(f => mappedFields.includes(f))
    const splitIndex = Math.floor(fields.length / FIELD_SLICE_FACTOR)
    const leftFields = fields.slice(0, splitIndex)
    const rightfields = fields.slice(splitIndex)
    return (
      <div className="ReferenceDetailForm__Columns">
        <div className="ReferenceDetailForm__Column ReferenceDetailForm__Column--inSection">
          <Field
            name="type"
            label={this.props.t('common.type')}
            options={referenceTypes.map(type => ({ value: type, label: t(`references:types.${type}.name`) }))} // TODO: translate label
            component={ReferenceAttributeSelect}
            readOnly={this.props.isExternalLib}
          />
          {leftFields.map(this.renderAttribute)}
        </div>
        <div className="ReferenceDetailForm__Column ReferenceDetailForm__Column--inSection">
          {this.renderLanguage()}
          {rightfields.map(this.renderAttribute)}
          {this.renderAbstract()}
        </div>
      </div>
    )
  }

  renderActions = () => {
    const { isExternalLib, isPremiumRestricted } = this.props
    return (
      <div className="ReferenceDetailForm__Actions">
        { !isExternalLib && (
        <DuplicateButton
          className="ActionToolbar__Button"
          onClick={this.handleDuplicateReference}
          circleModifier="circleMedium"
          enablePaywall={isPremiumRestricted}
        />
        )}
        { !isExternalLib && (
        <OutlinedButton
          className="ActionToolbar__Button"
          circleModifier="circleMedium"
          onClick={this.handleDelete}
        >
          <FontIcon icon={FontIcon.Icons.faTrashAlt} />
        </OutlinedButton>
        )}
      </div>
    )
  }

  renderAttachments = () => (
    <div className="ReferenceDetailForm__Attachment">
      <Field
        name="files"
        component={ReferenceFileField}
        readOnly={this.props.isExternalLib}
      />
    </div>
  )

renderRoles = () => {
  const { formValues, validRoles } = this.props
  return (
    <FieldArray
      name="roles"
      component={RolesFieldArray}
      formValues={formValues}
      validRoles={validRoles}
      readOnly={this.props.isExternalLib}
    />
  )
}

  render = () => {
    const {
      t, status, onRetry, isExternalLib, isDirty,
    } = this.props
    return (
      <div className="ReferenceDetailForm">
        <Prompt when={isDirty || status !== 'SYNCED'} message={t('common.unsavedChangesPrompt')} />
        <div className="ReferenceDetailForm__ScrollContainer">
          { this.renderActions() }
          { isExternalLib && <div className="ReferenceDetailForm__Hint">{t('references.externalRefReadOnlyHint')}</div>}
          <PageSection title={t('references.attributes')} />
          { this.renderAttributes() }
          <div className="ReferenceDetailForm__Columns">
            <PageSection title={t('references.role__plural')} className="ReferenceDetailForm__Column">
              { this.renderRoles() }
            </PageSection>
            <PageSection title={t('common.attachment')} className="ReferenceDetailForm__Column">
              { this.renderAttachments() }
            </PageSection>
            { !isExternalLib && (
            <AutoSaveButton
              className="ReferenceDetailForm__AutoSaveButton"
              status={status}
              onRetry={onRetry}
            />
            )}
          </div>
        </div>
        <DeleteReferenceModal id={DELETE_MODAL_ID} onConfirm={this.handleConfirmDeleteReference} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  referenceTypes: getReferenceTypes(state),
  validRoles: getValidRoles(state),
  isExternalLib: getIsExternalLib(state),
  isPremiumRestricted: isReferenceCountPremiumRestricted(state),
})

const mapDispatchToProps = {
  showModal,
  goBack,
  deleteReference,
  duplicateReference,
}

export default AutoSaveForm({
  form: 'ReferenceDetailForm',
  entitySelector: getReferenceFromPath,
  entityToFormValues: referenceToFormValues,
  formValuesToEntity: formValuesToReference,
  upsertEntityAction: upsertReference,
  skipDebounceAttributes: ['roles', 'type'],
  enableAutoSaveSelector: state => !getIsExternalLib(state),
})(connect(mapStateToProps, mapDispatchToProps)(translate()(ReferenceDetailForm)))

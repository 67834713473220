import { createAction } from 'redux-actions'
import { values } from 'lodash'
import { addToChildren, removeFromChildren, moveInChildren } from 'helper/hierarchyUtils'

import * as api from 'api'
import { receiveEntities, removeEntity, updateEntity } from 'actions/entities'
import { updateProjectCitationCluster } from 'actions/citation'
import { ObjectId } from 'helper/utils'
import { selector as referenceSelector } from 'selectors/references'
import tracker from 'tracking/tracker'
import { getProjectItem, isProjectItemInChildren } from 'selectors/project-items'
import { getSelectedProjectId } from 'selectors/path'
import {
  CITATION_PLACEHOLDER, CITATION_ID_ATTR,
} from 'helper/citation'

const removeFromStore = (dispatch, item) => {
  if (item.children && item.children.length > 0) {
    item.children.forEach(child => removeFromStore(dispatch, child))
  }
  dispatch(removeEntity({ entityType: 'projectItems', ...item }))
}

export const expandProjectItems = createAction('EXPAND_PROJECT_ITEMS')
export const collapseProjectItems = createAction('COLLAPSE_PROJECT_ITEMS')
export const expandCollapseProjectItem = createAction('EXPAND_COLLAPSE_PROJECT_ITEM')

export const selectProjectItemForMoveAndDropAction = createAction('SELECT_PROJECT_ITEM_FOR_MOVE_AND_DROP')

export const selectProjectItemForMoveAndDrop = id => dispatch => dispatch(selectProjectItemForMoveAndDropAction(id))

export const expandAllProjectItems = () => (dispatch, getState) => {
  const state = getState()
  // todo put in selector
  const selectedProjectId = getSelectedProjectId(state)
  const projectItemIds = values(state.entities.projectItems)
    .filter(item => item.projectId === selectedProjectId)
    .map(item => item.id)
  dispatch(expandProjectItems(projectItemIds))
}

export const collapseAllProjectItems = () => (dispatch, getState) => {
  const state = getState()
  // todo put in selector
  const selectedProjectId = getSelectedProjectId(state)
  const projectItemIds = values(state.entities.projectItems)
    .filter(item => item.projectId === selectedProjectId)
    .map(item => item.id)
  dispatch(collapseProjectItems(projectItemIds))
}
export const requestProjectItems = projectId => async (dispatch) => {
  const payload = await api.requestProjectItems(projectId)
  return dispatch(receiveEntities(payload))
}

export const createProjectItem = (type, data, parent, index) => async (dispatch, getState) => {
  const item = {
    id: ObjectId(),
    type,
    children: [],
    parentId: parent.id,
    projectId: parent.projectId,
    ...data,
  }

  const parentItem = getProjectItem(getState(), parent.id) // need to get clean object from store

  const patchedParentProjectItem = addToChildren(parentItem, item.id, index)

  const payloadBulkPatch = await api.bulkPatchProjectItem([item, patchedParentProjectItem])
  tracker.logEvent('CREATE_PROJECT_ITEM')

  return dispatch(receiveEntities(payloadBulkPatch))
}

export const moveProjectItem = (childId, index, newParentId, oldParentId) => async (dispatch, getState) => {
  const state = getState()
  if (isProjectItemInChildren(childId, newParentId)(state)) {
    return undefined
  }
  const itemsToUpdate = []
  let newParentItem = getProjectItem(state, newParentId)
  if (newParentId === oldParentId) {
    newParentItem = moveInChildren(newParentItem, childId, index)
    itemsToUpdate.push(newParentItem)
  } else {
    let oldParentItem = getProjectItem(state, oldParentId)
    //  move to other item children array
    oldParentItem = removeFromChildren(oldParentItem, childId)
    newParentItem = addToChildren(newParentItem, childId, index)
    itemsToUpdate.push(oldParentItem)
    itemsToUpdate.push(newParentItem)
  }
  const payloadBulkPatch = await api.bulkPatchProjectItem(itemsToUpdate)
  await dispatch(receiveEntities(payloadBulkPatch))
  return dispatch(updateProjectCitationCluster())
}

export const removeProjectItem = item => async (dispatch, getState) => {
  const parentItem = getProjectItem(getState(), item.parentId)
  const cleanedParentItem = removeFromChildren(parentItem, item.id)

  await api.deleteProjectItem(item)
  dispatch(updateEntity({ data: cleanedParentItem, entityType: 'projectItems' }))
  // all children of deleted item will be removed in api
  // in addition we remove them from our store manually
  removeFromStore(dispatch, item)
  dispatch(updateProjectCitationCluster())
}

export const updateProjectItem = data => async (dispatch, getState) => {
  const item = getProjectItem(getState(), data.id)

  const payloadBulkPatch = await api.bulkPatchProjectItem([{ ...data, children: item.children }])

  return dispatch(receiveEntities(payloadBulkPatch))
}

export const requestProjectContent = projectId => async (dispatch, getState) => {
  const projectItemIds = values(getState().entities.projectItems)
    .filter(item => item.projectId === projectId && item.type === 'TEXT')
    .map(item => item.id)
  const content = await api.requestProjectItemsContent(projectItemIds)
  return dispatch(receiveEntities(content))
}

const requestProjectItemsContent = projectItemIds => async (dispatch) => {
  const content = await api.requestProjectItemsContent(projectItemIds)
  return dispatch(receiveEntities(content))
}

export const transformProjectItemToText = id => async (dispatch, getState) => {
  const state = getState()
  const item = getProjectItem(getState(), id)
  const linkedNote = getState().entities.notes[item.noteId]

  if (!linkedNote) {
    throw new Error(`Cannot transform Note to text, because note ${item.noteId} does not exist.`)
  }

  const inlineCitations = []
  let inlineCites = ''
  linkedNote.referenceIds.forEach((referenceId) => {
    const reference = referenceSelector(state)[referenceId]
    if (reference) {
      // create inline citation
      const citation = {
        id: ObjectId(),
        items: [{
          referenceId,
        }],
      }


      inlineCites += `<br/><span 
            class="IntextCitation mceNonEditable"
            contenteditable=false
            ${CITATION_ID_ATTR}=${citation.id} 
          >${CITATION_PLACEHOLDER}</span>`

      inlineCitations.push(citation)
    }
  })

  const transformedItem = {
    ...item,
    type: 'TEXT',
    content: `<p>${linkedNote.title || ''}</p>${linkedNote.content}${inlineCites}`,
    inlineCitations: [...linkedNote.inlineCitations, ...inlineCitations],
  }
  const payloadBulkPatch = await api.bulkPatchProjectItem([transformedItem])
  tracker.logEvent('TRANSFORM_NOTE')

  await dispatch(receiveEntities(payloadBulkPatch))
  await dispatch(requestProjectItemsContent([item.id]))
  return dispatch(updateProjectCitationCluster())
}

export const uploadProjectItemImage = (item, file) => async (dispatch) => {
  const { fileId } = await api.uploadFileToS3(file)
  const payloadBulkPatch = await api.bulkPatchProjectItem([{ ...item, imageFileId: fileId }])
  return dispatch(receiveEntities(payloadBulkPatch))
}

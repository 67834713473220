import { schema } from 'normalizr'
import {
  compact, omitBy, isUndefined, isNull,
} from 'lodash'

export const subscriptionSchema = new schema.Entity('subscriptions')
export const subscriptionPlanSchema = new schema.Entity('subscriptionPlans')

export const projectItemSchema = new schema.Entity('projectItems')

export const noteSchema = new schema.Entity('notes')
export const projectSchema = new schema.Entity('projects')

export const referenceCollectionSchema = new schema.Entity('referenceCollections')

export const referenceSchema = new schema.Entity('references', {
  collections: [referenceCollectionSchema],
}, {
  processStrategy: (value) => {
    const reference = { ...value }
    if (reference.author) reference.author = compact(value.author)
    if (reference.editor) reference.editor = compact(value.editor)
    return omitBy(reference, prop => isUndefined(prop) || isNull(prop))
  },
})


export const externalReferenceSchema = new schema.Entity('externalReference')
export const externalReferencesSchema = new schema.Array(externalReferenceSchema)

export const npsSchema = new schema.Entity('netPromoterScore')

export const profileSchema = new schema.Entity('profiles', {
  subscriptions: {
    activeSubscription: subscriptionSchema,
    activeTemporarySubscription: subscriptionSchema,
  },
})

export const voucherSchema = new schema.Entity('vouchers')

export const scoreSchema = new schema.Entity('scores')

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import DOMPurify from 'dompurify'
import { NavLink } from 'react-router-dom'
import modifyClassName from 'helper/modifyClassName'

import './style.less'

const ReferenceCitation = ({
  className, reference, isLink, html,
}) => {
  if (!reference) {
    return <p className={className}>[Die Referenz ist nicht verfügbar]</p>
  }
  let content = html
  let noPrefix = false
  if (!content) {
    content = DOMPurify.sanitize(get(reference, 'citation'), { ALLOWED_ATTR: ['target', 'href', 'class'] })
    noPrefix = true
  }
  const props = {
    dangerouslySetInnerHTML: { __html: content },
    className: `${modifyClassName('ReferenceCitation', { noPrefix })} ${className || ''}`,
  }
  if (isLink) return <NavLink to={`/references/lib/${reference.id}`} {...props} />
  return <div {...props} />
}

ReferenceCitation.propTypes = {
  className: PropTypes.string,
  reference: PropTypes.shape({}),
  isLink: PropTypes.bool,
  html: PropTypes.string,
}

ReferenceCitation.defaultPropTypes = {
  isLink: false,
}

export default ReferenceCitation


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import modifyClassName from 'helper/modifyClassName'
import { sortByValue } from 'helper/utils'

import i18n, { propTypes as i18nPropTypes, translate } from 'i18n'
import { PremiumButton } from 'components/common/Button'

import { TabBar } from 'components/common/Bar'

import './style.less'


class SubscriptionPlans extends Component {
  constructor() {
    super()
    this.state = {
      selectedType: 'STANDARD',
    }
  }

  renderPlan = (connectedPlans) => {
    const { t, voucher } = this.props
    let mainPlan = connectedPlans.filter(plan => plan.duration === 12)[0] // make anual plan the main plan
    const monthlyPlan = connectedPlans.filter(plan => plan.duration === 1)[0]
    let isSpecial = false
    let duration = null
    if (!mainPlan && !monthlyPlan && connectedPlans.length === 1) {
      // special plan received
      // eslint-disable-next-line prefer-destructuring
      mainPlan = connectedPlans[0]
      duration = (mainPlan.duration >= 12 && mainPlan.duration % 12 === 0) ? t('subscription.yearWithCount', { count: parseInt(mainPlan.duration, 10) / 12 }) : t('subscription.monthWithCount', { count: parseInt(mainPlan.duration, 10) })
      isSpecial = true
    }

    const { quotas = {}, renewalPrice } = mainPlan

    let renewal = mainPlan.autoRenewal ? t('subscription.forever') : ''
    if (renewalPrice) {
      renewal = mainPlan.renewalDuration === 1 ? t(`subscription.renewalMonth${mainPlan.currency}`, { renewalPrice: (renewalPrice / 100) }) : t(`subscription.renewalYear${mainPlan.currency}`, { renewalPrice: (renewalPrice / 100) })
    }

    return (
      <div key={mainPlan.name} className={modifyClassName('SubscriptionPlan', { featured: mainPlan.isFeatured, disabled: !mainPlan.isValid })}>
        { mainPlan.isFeatured && <div className="SubscriptionPlan__FeaturedFlag">{t('subscription.featured')}</div>}
        { mainPlan.discount > 0 && (
        <div className="SubscriptionPlan__DiscountFlag">
          {t('subscription.discount', { discount: mainPlan.discount })}
        </div>
        )}

        { mainPlan.isValid && voucher && voucher.discount > 0 && (
        <div className="SubscriptionPlan__DiscountFlag SubscriptionPlan__DiscountFlag--voucher">
          {t('subscription.voucherDiscount', { discount: voucher.discount })}
        </div>
        )}

        <div className={modifyClassName('SubscriptionPlan__Title', { special: isSpecial })}>{i18n.exists(`subscription.plans.${mainPlan.name}`) ? t(`subscription.plans.${mainPlan.name}`) : mainPlan.label}</div>
        <div className="SubscriptionPlan__Price">
          {t(`common.price${mainPlan.currency}`, { price: (mainPlan.monthlyPrice / 100) })}
          <div className="SubscriptionPlan__PriceDuration">{t('subscription.perMonth')}</div>
        </div>
        {!isSpecial && <div className="SubscriptionPlan__PriceYearly">{t('subscription.billedAnnually')}</div>}
        {/* isValid is based on voucher configuration, it might happen that the mainplan (yearly)
          is valid but the according monthlyPlan is not */}
        {monthlyPlan && (monthlyPlan.isValid || (!mainPlan.isValid && !monthlyPlan.isValid)) && (
        <div className="SubscriptionPlan__PriceMonthly">
          {`${t(`common.price${monthlyPlan.currency}`, { price: (monthlyPlan.monthlyPrice / 100) })} ${t('subscription.billedMonthly')}`}
        </div>
        )}

        {/* isValid is based on voucher configuration, it might happen that the mainplan (yearly)
          is valid but the according monthlyPlan is not */}
        {!isSpecial && (!monthlyPlan || (mainPlan.isValid && !monthlyPlan.isValid)) && <div className="SubscriptionPlan__PriceMonthly">{t('subscription.noMonthlyPayment')}</div>}

        {isSpecial && (
        <div className="SubscriptionPlan__PriceSpecial">
          {`${t('subscription.oneTimePayment')} ${duration}`}
          <br />
          {renewal}
        </div>
        )}

        <ul className="SubscriptionPlan__Features">
          <li className="SubscriptionPlan__Feature">
            {t('subscription.allFeaturesAvailable')}
          </li>

          <li className="SubscriptionPlan__Feature">
            {quotas.notes ? t('subscription.features.projectQuota', { count: quotas.projects }) : t('subscription.features.unlimitedProjectQuota')}
          </li>
          <li className="SubscriptionPlan__Feature">
            {quotas.notes ? t('subscription.features.noteQuota', { count: quotas.notes }) : t('subscription.features.unlimitedNoteQuota')}
          </li>
          <li className="SubscriptionPlan__Feature">
            {quotas.references ? t('subscription.features.referenceQuota', { count: quotas.references }) : t('subscription.features.unlimitedReferenceQuota')}
          </li>
        </ul>
        <div className="SubscriptionPlan__CTA">
          <PremiumButton onClick={() => mainPlan.isValid && this.props.onSelect(connectedPlans)} disabled={!mainPlan.isValid}>
            {t('subscription.buyButtonCTA')}
          </PremiumButton>
        </div>
      </div>
    )
  }

  render() {
    const { plans, t } = this.props
    const tabValues = Object.keys(plans).sort(sortByValue(['STANDARD', 'EDUCATION', 'VOUCHER']))
    let selectedTypeValue = this.state.selectedType
    // eslint-disable-next-line prefer-destructuring
    if (!plans[selectedTypeValue]) selectedTypeValue = Object.keys(plans)[0]

    return (
      <div className="SubscriptionPlans">
        <TabBar
          className="SubscriptionPlans__Bar"
          onSelect={selectedType => this.setState({ selectedType })}
          selectedValue={selectedTypeValue}
          values={tabValues}
          translationKeys={tabValues.map(v => `subscription.types.${v}`)}
        />
        <div className="SubscriptionPlans__Plans">
          {values(plans[selectedTypeValue]).map(connectedPlans => this.renderPlan(connectedPlans))}
        </div>
        <div className="SubscriptionPlans__Text">
          {t('subscription.explainEducation')}
        </div>
      </div>

    )
  }
}

SubscriptionPlans.propTypes = {
  ...i18nPropTypes,
  plans: PropTypes.shape({}),
  voucher: PropTypes.shape({}),
}

export default translate()(SubscriptionPlans)

import React from 'react'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import FontIcon from 'components/common/FontIcon'

import withTooltip from 'containers/common/Tooltip'
import modifyClassName from 'helper/modifyClassName'


import './style.less'

const options = [{
  optionValue: 'person',
  icon: FontIcon.Icons.faUser,
}, {
  optionValue: 'organization',
  icon: FontIcon.Icons.faBuilding,
}]

const ReferenceTypeSwitchButton = withTooltip(({
  onChange, value, icon, optionValue, readOnly,
}) => (
  <button key={optionValue} onClick={!readOnly ? (() => onChange(optionValue)) : undefined}>
    <FontIcon
      icon={icon}
      className={modifyClassName('ReferenceTypeSwitch__Icon', { active: value === optionValue, readOnly })}
    />
  </button>
))

ReferenceTypeSwitchButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.object,
  optionValue: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  readOnly: PropTypes.bool,
}

const ReferenceTypeSwitch = ({ input, t, readOnly }) => {
  if (!input) return null
  const { onChange, value } = input
  return (
    <div className="ReferenceTypeSwitch">
      {options.map(({ icon, optionValue }) => (
        <ReferenceTypeSwitchButton
          key={optionValue}
          icon={icon}
          optionValue={optionValue}
          value={value}
          onChange={onChange}
          tooltipText={t(`references.${optionValue}`)}
          tooltipPlacement="bottom"
          readOnly={readOnly}
        />
      ))}
    </div>
  )
}

ReferenceTypeSwitch.propTypes = {
  ...i18nPropTypes,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  readOnly: PropTypes.bool,
}

export default translate()(ReferenceTypeSwitch)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import FeatureSelector from 'selectors/features'

import './style.less'

class FeatureFlag extends Component {
  render() {
    if (this.props.featureEnabled) {
      return (
        <div className="FeatureFlag">
          {this.props.children}
        </div>
      )
    }
    return <div className="FeatureFlag FeatureFlag--disabled" />
  }
}

FeatureFlag.propTypes = {
  feature: PropTypes.string,
  featureEnabled: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => ({
  featureEnabled: FeatureSelector.featureEnabled(state, ownProps.feature),
})


export default connect(mapStateToProps)(FeatureFlag)

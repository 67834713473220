import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import { toggleHelpSystem } from 'actions/help'
import SubscriptionSelector from 'selectors/subscriptions'
import NoteSelector from 'selectors/notes'
import ProfileSelector from 'selectors/profile'
import NavBarButton from 'components/navigation/NavBarButton'
import FeatureFlag from 'containers/FeatureFlag'
import { AuratikumFontIcons } from 'helper/utils'
import modifyClassName from 'helper/modifyClassName'
import ChecklistProgressNavIcon from 'containers/onboarding/ChecklistProgressNavIcon'
import ChecklistPanel from 'containers/onboarding/ChecklistPanel'
import FontIcon from 'components/common/FontIcon'

import HelpSelector from 'selectors/help'
import { getActiveProjectId, getNavigationContext } from 'selectors/navigation'
import ProjectsSelector from 'selectors/projects'

import './style.less'

const shortenUserName = (userName) => {
  if (userName.indexOf(' ') >= 0) {
    return userName.split(' ').map(p => p[0]).join('').substring(0, 2)
  }
  return userName.substring(0, 1)
}

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showProjectsFlyout: false,
    }
  }

  renderProjectsMenu = () => {
    const { projects, activeProjectId, navigate } = this.props
    if (projects.length === 0) return null
    return (
      <div className="NavBar__ProjectsMenu">
        { projects.map(project => (
          <button
            key={project.id}
            className={modifyClassName('NavBar__ProjectsMenuButton', { active: activeProjectId === project.id })}
            onClick={() => navigate(`/projects/${project.id}`)}
          >
            {project.title}
          </button>
        ))}
      </div>
    )
  }

  render = () => {
    const {
      t,
      expanded,
      hasSubscription,
      toggleHelp,
      userName,
      helpSystemEnabled,
      location,
      navigationContext,
    } = this.props
    return (
      <div className={`NavBar ${expanded ? 'NavBar--expanded' : ''}`}>
        <NavLink to="/projects" className={`NavBar__Brand ${expanded ? 'NavBar__Brand--expanded' : ''}`}>
          { expanded ? 'Auratikum' : 'A' }
        </NavLink>

        <NavBarButton
          icon={AuratikumFontIcons.CLIPBOARD}
          linkPath="/projects"
          expanded={expanded}
          tooltipDisabled
          tooltipTimeout={0}
          selected={navigationContext === 'projects'}
          menuComponent={this.renderProjectsMenu()}
        >
          {t('common.projects')}
        </NavBarButton>

        <NavBarButton
          icon={AuratikumFontIcons.REFERENCE}
          linkPath="/references"
          expanded={expanded}
          translationKey="helpSpot.nav.references"
          tooltipText={t('nav.references')}
          tooltipDisabled={expanded}
          selected={navigationContext === 'references'}
          tooltipTimeout={0}
        >
          {t('nav.references')}
        </NavBarButton>

        <div className="NavBar__Separator" />

        <FeatureFlag feature="checklist">
          <ChecklistProgressNavIcon
            expanded={expanded}
            tooltipText={t('checklist.navButton')}
            tooltipDisabled={expanded}
            tooltipTimeout={0}
          />
        </FeatureFlag>

        <NavBarButton
          icon={AuratikumFontIcons.QUESTIONMARK}
          onClick={toggleHelp}
          expanded={expanded}
          helpSystemEnabled={helpSystemEnabled}
          tooltipText={t('nav.help')}
          tooltipDisabled={expanded}
          tooltipTimeout={0}
        >
          {t('nav.help')}
        </NavBarButton>
        <FeatureFlag feature="payment">
          { !hasSubscription && (
            <NavBarButton
              icon={AuratikumFontIcons.CROWN}
              linkPath="/profile/premium"
              premium
              expanded={expanded}
              tooltipText={t('nav.premium')}
              tooltipDisabled={expanded}
              tooltipTimeout={0}
            >
              {t('nav.premium')}
            </NavBarButton>
          )
          }
        </FeatureFlag>
        <FeatureFlag feature="challenge">
          <NavBarButton
            icon={<FontIcon icon={FontIcon.Icons.faTrophyAlt} />}
            linkPath="/profile/challenge"
            premium
            expanded={expanded}
            tooltipText={t('nav.challenge')}
            tooltipDisabled={expanded}
            tooltipTimeout={0}
          >
            {t('nav.challenge')}
          </NavBarButton>
        </FeatureFlag>
        <FeatureFlag feature="referral">
          <NavBarButton
            icon={<FontIcon icon={FontIcon.Icons.faHeart} />}
            linkPath="/profile/referral"
            expanded={expanded}
            premium
            tooltipText={t('nav.referral')}
            tooltipDisabled={expanded}
            tooltipTimeout={0}
          >
            {t('nav.referral')}
          </NavBarButton>
        </FeatureFlag>

        <NavBarButton
          icon={shortenUserName(userName)}
          linkPath="/profile"
          selected={location.pathname.includes('profile')}
          profile
          expanded={expanded}
          tooltipText={t('nav.profile')}
          tooltipDisabled={expanded}
          tooltipTimeout={0}
        >
          {userName}
        </NavBarButton>
        <div id="root-toast" className={modifyClassName('NavBar__ToastContainer', { expanded })} />
        <ChecklistPanel className={modifyClassName('NavBar__ChecklistPanel', { expanded })} />

      </div>

    )
  }
}

NavBar.propTypes = {
  visible: PropTypes.bool,
  expanded: PropTypes.bool,
  hasSubscription: PropTypes.bool,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  navigate: PropTypes.func,
  toggleHelp: PropTypes.func,
  userName: PropTypes.string.isRequired,
  navigateToNotes: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  activeProjectId: PropTypes.string,
  navigationContext: PropTypes.string,
  ...i18nPropTypes,
}

const mapStateToProps = (state, ownProps) => ({
  expanded: ownProps.location.pathname === '/projects',
  helpSystemEnabled: HelpSelector.isEnabled(state),
  hasSubscription: SubscriptionSelector.hasSubscription(state),
  noteCount: NoteSelector.getNoneInitialCount(state),
  maxNoteCount: ProfileSelector.getMaxNoteCount(state),
  userName: ProfileSelector.getUserName(state),
  projects: ProjectsSelector.getAll(state),
  activeProjectId: getActiveProjectId(state),
  navigationContext: getNavigationContext(state),
})

const mapDispatchToProps = dispatch => ({
  navigate: location => dispatch(push(location)),
  toggleHelp: () => dispatch(toggleHelpSystem()),
  navigateToNotes: () => {
    dispatch(push('/notes'))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate()(NavBar)))

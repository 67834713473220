import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'

import { debounce, get, findIndex } from 'lodash'
import { Prompt } from 'react-router'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import TextEditor from 'containers/common/TextEditor'
import { OutlinedButton, OutlinedSecondaryButton } from 'components/common/Button'
import Icon from 'components/common/Icon'
import { AuratikumFontIcons } from 'helper/utils'
import modifyClassName from 'helper/modifyClassName'

import Spinner from 'components/common/Spinner'
import { CitationStyleType, updateDomCitations } from 'helper/citation'
import Footnote from 'components/common/Footnote'

import WritingMovable from '../WritingMovable'

import WritingPortal from '../WritingPortal'
import WritingPortalElements from '../WritingPortalElements'

import './style.less'

const DEBOUNCE_TIME = 1000

class WritingText extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string,
    }),
    updateProjectItem: PropTypes.func,
    onSelectForMoveAndDrop: PropTypes.func,
    removeProjectItem: PropTypes.func,
    index: PropTypes.number,
    isLoadingContent: PropTypes.bool,
    onSetupEditor: PropTypes.func,
    updateProjectCitationCluster: PropTypes.func.isRequired,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      isEditorVisible: false,
      content: props.item.content,
      savingStatus: 'NONE',
      showFlyout: false,
      dimensions: {
        top: 0,
        height: 0,
      },
    }
    this.debouncedSave = debounce(this.handleSave, DEBOUNCE_TIME)
    this.shouldMeasure = false
    this.contentReadRef = React.createRef()
  }


  componentWillReceiveProps = (props) => {
    if ((this.state.content === null || this.state.content === undefined) && props.item.content) {
      this.setState({ content: props.item.content })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.measure !== undefined && (prevProps.index !== this.props.index || prevProps.containerHeight !== this.props.containerHeight)) {
      setTimeout(this.measure, 1)
    }
    if (get(this.props, 'item.citationCluster') !== get(prevProps, 'item.citationCluster')) {
      this.rerenderCitations()
    }
  }


  handleResize = (contentRect) => {
    this.setState({ dimensions: contentRect.offset })
  }

  handleRetry = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.shouldClose = false
    this.handleSave()
    this.editor.focus({ preventScroll: true })
  }

  handleSave = async () => {
    const { savingStatus, content: stateContent } = this.state
    const { item = {}, updateProjectItem: updateItem } = this.props

    if (savingStatus === 'SAVING') {
      return
    }

    this.setState({ savingStatus: 'SAVING' })
    let s = {}
    try {
      await updateItem({ ...item, content: stateContent })
      s = { savingStatus: 'SAVED' }
    } catch (error) {
      s = { savingStatus: 'ERROR' }
    }
    if (s.savingStatus !== 'ERROR' && this.shouldClose) {
      s.isEditing = false
      this.shouldClose = false
    }
    this.setState(s)
    if (s.savingStatus !== 'ERROR' && !stateContent && !item.content && stateContent !== item.content) {
      this.handleSave()
    } else if (s.savingStatus === 'SAVED') {
      setTimeout(() => {
        this.setState({ savingStatus: 'NONE' })
      }, 2000)
    }
    this.rerenderCitations()
  }

  handleSetup = (editor) => {
    const { onSetupEditor } = this.props
    this.editor = editor
    this.setState({ isEditorVisible: false })

    editor.on('blur', () => {
      if (!this.state.showFlyout) {
        this.setState({
          content: editor.getContent(),
        })
        this.shouldClose = true
        this.handleSave()
      }
    })

    editor.on('init', () => {
      this.setState({ isEditorVisible: true })
      editor.focus({ preventScroll: true })

      if (onSetupEditor) {
        try { onSetupEditor() } catch (err) {
          // ignore
        }
      }

      const getNextChildNode = (el, indices) => {
        if (!el) return null
        if (indices.length === 0 || el.childNodes === undefined) return el
        const newIndices = [...indices]
        newIndices.splice(0, 1)
        return getNextChildNode(el.childNodes[indices[0]], newIndices)
      }

      if (this.state.treeIndices) {
        try {
          const node = getNextChildNode(editor.getBody(), this.state.treeIndices)
          if (node) {
            const range = document.createRange()

            range.setStart(node, this.state.selectionOffset)
            range.setEnd(node, this.state.selectionOffset)

            editor.selection.setRng(range)
          } else {
            editor.selection.select(editor.getBody(), true)
          }
        } catch (e) {
          editor.selection.select(editor.getBody(), true)
        }
      } else {
        editor.selection.select(editor.getBody(), true)
      }

      editor.selection.collapse(false)
    })
  }

  handleRemove = () => this.props.removeProjectItem(this.props.item)

  handleShowFlyout = () => {
    this.setState({ showFlyout: true })
  }

  handleHideFlyout = () => {
    this.setState({ showFlyout: false })
  }

  handleEditorChange = (content) => {
    const { content: currentContent } = this.state
    if ((!currentContent && !content) || currentContent === content) {
      return
    }
    this.setState({ content })
    this.debouncedSave()
  }

  startEdit = () => {
    const getIndexInParentChilds = (el) => {
      if (!el || !el.parentNode) {
        return [] // weird condition that happens in move-mode
      }

      const children = Array.from(el.parentNode.childNodes)
      const index = children.indexOf(el)

      // got to the top
      if (el.parentNode.className && el.parentNode.className.includes('WritingText__Read')) {
        const childrenWoText = children.filter(child => child.nodeType !== Node.TEXT_NODE)
        return [childrenWoText.indexOf(el)]
      }

      const indices = getIndexInParentChilds(el.parentNode)
      indices.push(index)
      return [...indices]
    }
    if (this.props.item.content) {
      const selection = window.getSelection()
      const treeIndices = getIndexInParentChilds(selection.focusNode)
      this.setState({ isEditing: true, treeIndices, selectionOffset: selection.focusOffset })
    } else {
      this.setState({ isEditing: true })
    }
  }


  handleAddCitation = async ({ citation, index }) => {
    const { updateProjectItem, item, updateProjectCitationCluster } = this.props
    const { inlineCitations = [] } = item

    inlineCitations.splice(index, 0, citation)

    await updateProjectItem({ ...item, inlineCitations })
    await updateProjectCitationCluster()
    this.rerenderCitations()
  }

  handleSaveCitation = async ({ citation, index }) => {
    const { updateProjectItem, item, updateProjectCitationCluster } = this.props
    const newInlineCitations = [...item.inlineCitations]

    const cleanedId = citation.id.substring(0, citation.id.indexOf('-'))

    const inlineCitationsIndex = findIndex(newInlineCitations, c => c.id === cleanedId)
    if (inlineCitationsIndex >= 0) {
      newInlineCitations[inlineCitationsIndex] = { ...citation, id: cleanedId }
    } else {
      // edge case, the content has an invalid citation which is not available in inlineCitations any more
      // handle it lite addCitation
      newInlineCitations.splice(index, 0, { ...citation, id: cleanedId })
    }

    await updateProjectItem({ ...item, inlineCitations: newInlineCitations })
    await updateProjectCitationCluster()
    this.rerenderCitations()
  }

  handleRemoveCitations = async (removedCitationIds) => {
    const { updateProjectItem, item } = this.props
    const { inlineCitations = [] } = item

    const newInlineCitations = inlineCitations.filter(citation => !removedCitationIds.includes(citation.id))
    await updateProjectItem({ ...item, inlineCitations: newInlineCitations })
    return this.props.updateProjectCitationCluster()
  }

  rerenderCitations() {
    const el = this.contentReadRef.current
    if (el) {
      const citationEls = [...el.querySelectorAll('.IntextCitation')]
      updateDomCitations(citationEls, this.props.item.citationCluster.citations, this.props.item.citationCluster.type)
    }
  }

  renderRead = () => {
    if (this.props.item.content && this.props.item.content.length > 0) {
      // some browser don't support html markup inside buttons
      // eslint-disable-next-line
      return (<div
        id={`WritingText-Read-${this.props.item.id}`}
        className={modifyClassName('WritingText__Read', { /* hidden: this.state.isEditorVisible */ })}
        onClick={this.startEdit}
        dangerouslySetInnerHTML={{ __html: this.props.item.content }}
        ref={this.contentReadRef}
      />)
    }
    return <button type="button" onClick={this.startEdit} className={modifyClassName('WritingText__ReadEmpty', {/* hidden: this.state.isEditorVisible */ })}>{this.props.t('writing.emptytextblock')}</button>
  }

  renderEdit = () => (
    <React.Fragment>
      <TextEditor
        id={`WritingText-Edit-${this.props.item.id}`}
        toolbarClass=".WritingContainer__EditorToolbar"
        value={this.state.content}
        onChange={this.handleEditorChange}
        onSave={this.handleSave}
        onSetup={this.handleSetup}
        onAddCitation={this.handleAddCitation}
        onSaveCitation={this.handleSaveCitation}
        onRemoveCitation={this.handleRemoveCitations}
        onShowFlyout={this.handleShowFlyout}
        onHideFlyout={this.handleHideFlyout}
        className={modifyClassName('WritingText__Editor', { hidden: !this.state.isEditorVisible })}
        citationCluster={this.props.item.citationCluster}
        customSettings={{ projectItemId: this.props.item.id }}
      />
    </React.Fragment>
  )

  renderText() {
    const { item, t } = this.props
    return (
      <React.Fragment>
        <Prompt when={item.content !== this.state.content} message={t('common.unsavedChangesPrompt')} />
        { this.state.savingStatus === 'SAVING' && (
        <div className="WritingText__Saving">
          <Spinner />
          {t('writing.saving')}
        </div>
        ) }
        { this.state.savingStatus === 'SAVED' && <div className="WritingText__Saving">{t('writing.allSaved')}</div> }
        { this.state.savingStatus === 'ERROR' && (
        <div className="WritingText__Saving WritingText__Saving--error">
          {t('writing.errorSaved')}
          <button type="button" className="WritingText__RetryButton" onClick={this.handleSave}>{t('common.retry')}</button>
        </div>
        ) }
        {!this.state.isEditing || (this.state.isEditing && !this.state.isEditorVisible) ? this.renderRead() : <div />}
        {this.state.isEditing ? this.renderEdit() : <div />}
        { item.citationCluster.type === CitationStyleType.FOOTNOTE && get(item, 'citationCluster.citations', []).map(citation => <Footnote key={citation.citationId} citation={citation} />) }
      </React.Fragment>
    )
  }

  render() {
    const { top = 0 } = this.state.dimensions
    const {
      t, item, isLoadingContent, movingModeActive,
    } = this.props
    return (
      <Measure
        offset
        bounds
        onResize={this.handleResize}
      >
        {({ measure, measureRef }) => {
          // have to expose measure function to class to trigger the function on scroll events aswell
          this.measure = measure
          return (
            <div ref={measureRef} className="WritingText">
              { isLoadingContent ? (
                <div>
                  <Spinner />
                  {t('writing.loadingTextContent')}
                </div>
              ) : this.renderText() }
              <div className={modifyClassName('WritingText__Actions', { hidden: movingModeActive })}>
                <OutlinedSecondaryButton className="WritingText__Action" onClick={this.props.onSelectForMoveAndDrop} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.MOVE} /></OutlinedSecondaryButton>
                <OutlinedButton className="WritingText__Action" onClick={this.handleRemove} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.DELETE} /></OutlinedButton>
              </div>
              <WritingPortal key={`Portal-${item.id}`} childId={item.id} type={item.type} top={top}>
                <WritingPortalElements
                  itemId={item.id}
                  note={item.noteId && item.note}
                  rootElementId={this.state.isEditorVisible ? `WritingText-Edit-${item.id}` : `WritingText-Read-${item.id}`}
                  rootElementDimensions={this.state.dimensions}
                />
              </WritingPortal>
            </div>
          )
        }
        }
      </Measure>
    )
  }
}

export default WritingMovable(translate()(WritingText))

import { getCsl, selector as getReferenceEntities } from 'selectors/references'
import { getSelectedProjectId } from 'selectors/path'

import {
  flatten, intersection,
} from 'lodash'


import { getProfileLanguage } from 'selectors/profile'
import { createAction } from 'redux-actions'
import { getInlineCitationsForSelectedProject } from 'selectors/projects'
import {
  generateCitations,
} from 'helper/citation'

export const updateCitationCluster = createAction('UPDATE_CITATION_CLUSTER')


export const updateNoteCitationCluster = note => async (dispatch, getState) => {
  const state = getState()
  const references = getReferenceEntities(state)

  let citedReferenceIds = flatten(note.inlineCitations.map(
    citation => (citation.referenceIds ? citation.referenceIds : citation.items.map(item => item.referenceId)),
  ))
  citedReferenceIds = intersection(citedReferenceIds, Object.keys(references))
  // const filteredReferences = filterEntityByIds(references, referenceIds)
  const citationData = await generateCitations(
    note.id, references, citedReferenceIds, note.referenceIds, getCsl(state), getProfileLanguage(state), note.inlineCitations,
  )

  return dispatch(updateCitationCluster({ noteId: note.id, citationData }))
}

export const updateProjectCitationCluster = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getSelectedProjectId(state)
  const { citations, projectItems, uncitedReferenceIds } = getInlineCitationsForSelectedProject(state)
  const references = getReferenceEntities(state)

  let citedReferenceIds = flatten(citations.map(
    citation => (citation.referenceIds ? citation.referenceIds : citation.items.map(item => item.referenceId)),
  ))
  citedReferenceIds = intersection(citedReferenceIds, Object.keys(references))
  // const filteredReferences = filterEntityByIds(references, referenceIds)
  const citationData = await generateCitations(
    projectId, references, citedReferenceIds, uncitedReferenceIds, getCsl(state), getProfileLanguage(state), citations,
  )

  return dispatch(updateCitationCluster({ projectId, citationData: { ...citationData, projectItems } }))
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { showModal } from 'actions/modal'
import { push } from 'connected-react-router'

import { getLibraryReferences } from 'selectors/references'

import { PlainReferenceListItem } from 'components/references/ReferenceListItem'
import ReferenceSearchField from 'containers/references/ReferenceSearchField'
import ScrollRestoration from 'containers/common/ScrollRestoration'

import logoGoogleBooks from 'assets/logoGoogleBooks.png'

import './style.less'

// TODO encapsulate list when adding additional external libs

const ReferenceLibContainer = (libraryKey, logo) => {
  class ReferenceLibExternalContainer extends Component {
    static propTypes = {
      ...i18nPropTypes,
      references: PropTypes.array,
    }

    componentDidUpdate(prevProps) {
      if (this.props.references === prevProps.references || !this.scrollToTop) {
        return
      }
      this.scrollToTop()
    }

    handleSelect = (reference) => {
      this.props.push(`/references/${libraryKey}/${reference.id}`)
    }

    handleScrollToTopRef = (scrollToTopRef) => { this.scrollToTop = scrollToTopRef }

    renderEmptyState() {
      const { t } = this.props
      return (
        <div className="ReferenceLibContainer">
          <div className="ReferenceLibContainer__EmptyContainer">
            <div className="ReferenceLibContainer__EmptyTitle">{t(`references.libraries.${libraryKey}.description`)}</div>
            <ReferenceSearchField autoFocus />
            <div className="ReferenceLibContainer__EmptyPoweredBy">
              <div className="ReferenceLibContainer__EmptyPoweredByText">{t('references.libraries.poweredBy')}</div>
              <img className="ReferenceLibContainer__EmptyPoweredByLogo" src={logo} width="200" height="68" alt="Logo" />
            </div>
          </div>
        </div>
      )
    }

    render() {
      const { references, t } = this.props
      if (!references || references.length === 0) return this.renderEmptyState()
      return (
        <ScrollRestoration
          id={`EXTERNAL_LIBRARY_SEARCH_SCROLL_${libraryKey}`}
          className="ReferenceLibContainer"
          scrollToTopHandler={this.handleScrollToTopRef}
        >
          <div className="ReferenceLibContainer__Items">
            {references.map((reference, index) => (
              <PlainReferenceListItem
                key={reference.id || index}
                reference={reference}
                onSelect={this.handleSelect}
                isExternalReference
              />
            ))}
            { references.length === 20 && (
            <div className="ReferenceLibContainer__NoPaging">
              {t('references.libraries.truncatedList')}
            </div>
            )}
          </div>
        </ScrollRestoration>
      )
    }
  }

  const referenceSelector = getLibraryReferences(libraryKey)

  const mapStateToProps = state => ({
    references: referenceSelector(state),
  })

  return connect(mapStateToProps, {
    showModal,
    push,
  })(translate()(ReferenceLibExternalContainer))
}


export const ReferenceLibCrossrefContainer = ReferenceLibContainer('crossref', 'https://assets.crossref.org/logo/crossref-logo-landscape-200.svg')
export const ReferenceLibPubmedContainer = ReferenceLibContainer('pubmed', 'https://www.nlm.nih.gov/about/logos_nlm_photos/pubmed_logo.png')
export const ReferenceLibGoogleBooksContainer = ReferenceLibContainer('google-books', logoGoogleBooks)

import i18n from 'i18n'
import uuidV4 from 'uuid/v4'

import tracker from 'tracking/tracker'
import { cognitoGetToken as getToken, cognitoGetUser as getUser } from 'integrations/AWSCognito'
import { normalize } from 'normalizr'
import { get, isFunction } from 'lodash'
import connector from './connector'

import {
  noteSchema,
  projectSchema,
  projectItemSchema,
  referenceSchema,
  profileSchema,
  subscriptionSchema,
  subscriptionPlanSchema,
  npsSchema,
  externalReferencesSchema,
  voucherSchema,
  referenceCollectionSchema,
  scoreSchema,
} from './schema'

const { API_ROOT } = process.env
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024

// const API_ROOT = 'https://vty2vwdmal.execute-api.eu-central-1.amazonaws.com/live'

const getEndpoint = path => (...segments) => `${API_ROOT}${path}${segments ? segments.map(segment => `/${segment}`) : ''}`
const Endpoints = {
  referenceCollection: getEndpoint('/reference-collections'),
  scoreBoard: getEndpoint('/score-board'),
  earlyBird: getEndpoint('/score-board/early-bird'),
  formula: getEndpoint('/latex-equation'),
}

/**
 *
 * @param {*} uri path
 * @param {*} options {method: string, data?: object, tracking?: {event: string, data: payload => object | object}, schema?: object }
 */
const apiRequest = async (uri, {
  method, data, tracking, schema, isPublic,
} = {}) => {
  const payload = isPublic ? await connector.request(null, uri, method, data) : await connector.authenticatedRequest(uri, method, data)
  if (tracking) {
    const { data: additionalData } = tracking
    tracker.logEvent(tracking.event, isFunction(additionalData) ? additionalData(payload) : additionalData)
  }
  return schema ? normalize(payload, schema) : payload
}

// https://stackoverflow.com/a/18116302
const getQueryString = queries => Object
  .keys(queries)
  .filter(k => queries[k] !== null && queries[k] !== undefined)
  .map(k => `${k}=${encodeURIComponent(queries[k])}`).join('&')

// Note
export const createNote = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/notes`, 'POST', data)

  tracker.logEvent('CREATE_NOTE', { id: payload.id })

  return normalize(payload, noteSchema)
}

export const updateNote = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/notes/${data.id}`, 'PATCH', data)

  return normalize(payload, noteSchema)
}

export const deleteNote = async (data, options) => {
  const token = await getToken()
  let params = ''
  if (options.dryRun) params = '?dryRun=true'
  if (options.force) params = '?force=true'
  const result = await connector.request(token, `${API_ROOT}/notes/${data.id}${params}`, 'DELETE', data)

  if (!options.dryRun) {
    tracker.logEvent('DELETE_NOTE', { id: data.id })
  }

  return result
}

export const requestNotes = async () => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/notes`).then(payload => normalize(payload, [noteSchema]))

  return result
}

export const requestNote = async (id) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/notes/${id}`)
  const result = normalize(payload, noteSchema)
  return result
}

export const searchNotes = async (searchTerm, filterTags) => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/notes/search?q=${searchTerm}&tags=${filterTags.join(',')}`)
  tracker.logEvent('SEARCH', { searchTerm })

  return result
}

export const searchNoteSuggestions = async (searchTerm, filterTags) => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/notes/searchSuggestions?q=${searchTerm}&tags=${filterTags.join(',')}`)

  return result
}

// Project
export const requestProjects = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/projects`)
  return normalize(payload, [projectSchema])
}

export const createProject = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/projects`, 'POST', data)
  tracker.logEvent('CREATE_PROJECT', { id: payload.id })

  return normalize(payload, projectSchema)
}

export const updateProject = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/projects/${data.id}`, 'PATCH', data)
  tracker.logEvent('UPDATE_PROJECT', { id: payload.id })

  return normalize(payload, projectSchema)
}

export const deleteProject = async (data) => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/projects/${data.id}`, 'DELETE', data)
  tracker.logEvent('DELETE_PROJECT', { id: data.id })

  // return connector.request(token, `${API_ROOT}/projects/${data.id}`, 'DELETE', data)

  return result
}

// ProjectItem
export const requestProjectItems = async (projectId) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/projects/${projectId}/project-items`)
  return normalize(payload, [projectItemSchema])
}

export const requestProjectItemsContent = async (projectItemIds) => {
  const token = await getToken()
  const data = { projectItemsIds: projectItemIds }
  const payload = await connector.request(token, `${API_ROOT}/project-items/content`, 'POST', data)
  return normalize(payload, [projectItemSchema])
}

export const bulkPatchProjectItem = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/project-items`, 'PATCH', data)

  return normalize(payload, [projectItemSchema])
}

export const deleteProjectItem = async (data) => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/project-items/${data.id}`, 'DELETE', data)
  tracker.logEvent('DELETE_PROJECTITEM', { id: data.id })
  return result
}

export const exportProject = async (project, format, outline) => {
  const token = await getToken()
  const result = await connector.downloadFileThroughAPI(
    token,
    `${API_ROOT}/exports/?id=${project.id}&format=${format}&outline=${outline}`,
    project.title,
  )
  tracker.logEvent('EXPORT', { id: project.id, format })

  return result
}

export const backup = async (format) => {
  const token = await getToken()
  const result = await connector.downloadFileThroughAPI(token, `${API_ROOT}/backup/?format=${format}`)
  tracker.logEvent('BACKUP', { format })

  return result
}

// Refererence
export const createReference = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/references`, 'POST', data)
  return normalize(payload, referenceSchema)
}

export const searchExternalReferences = async (library, query) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/references/search?service=${library}&q=${query}`, 'GET')
  const references = payload.results.map(ref => ({ ...ref, id: uuidV4(), fromLibrary: library }))
  return {
    ...normalize(references, externalReferencesSchema),
    totalCount: payload.totalCount,
  }
}

export const updateReference = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/references/${data.id}`, 'PATCH', data)

  return normalize(payload, referenceSchema)
}

export const deleteReference = async (data) => {
  const token = await getToken()
  const result = await connector.request(token, `${API_ROOT}/references/${data.id}`, 'DELETE', data)
  tracker.logEvent('DELETE_REFERENCE', { id: data.id })

  return result
}

export const requestReferences = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/references`)
  return normalize(payload, [referenceSchema])
}

export const requestTags = async () => {
  const token = await getToken()
  return connector.request(token, `${API_ROOT}/notes/tags`)
}

export const requestExportBib = async (format, collectionId) => {
  const token = await getToken()
  const result = await connector.downloadFileThroughAPI(token, `${API_ROOT}/references/export?format=${format}${collectionId ? `&collection=${collectionId}` : ''}`)
  return result
}

export const createReferenceFromData = async (data) => {
  const token = await getToken()
  const { type, reference } = await connector.request(token, `${API_ROOT}/references/create`, 'POST', data)
  return {
    result: normalize(reference, referenceSchema),
    type,
  }
}

// Files
export const uploadFileToS3 = async (file, options) => {
  const opts = {
    maxSize: DEFAULT_MAX_FILE_SIZE,
    documentType: 'attachment',
    ...options,
  }
  if (file.size > opts.maxSize) {
    throw new Error(i18n.t('upload.fileTooLarge', { maxSize: opts.maxSize / 1024 / 1024 }))
  }
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/files/presigned-upload-url?documentType=${opts.documentType}`)
  await connector.uploadToS3(payload.uploadUrl, file)
  return payload
}

export const getDownloadURLForS3 = async (file) => {
  const token = await getToken()
  return connector.request(token, `${API_ROOT}/files/presigned-download-url?fileId=${file.fileId}`)
}

export const downloadFileFromS3 = async (file) => {
  const payload = await getDownloadURLForS3(file)
  await connector.downloadFromS3(payload.url, file.fileName)
}


// Styles & CSL

export const requestCSL = async style => connector.cslRequest(style)

export const searchStyles = async (query) => {
  const token = await getToken()
  return connector.request(token, `${API_ROOT}/styles/search?q=${query}`, 'GET')
}

// Initialize User
export const initializeUser = async (language, token, trafficSource) => {
  const authToken = await getToken()
  const payload = await connector.request(authToken, `${API_ROOT}/initialize-user`, 'POST', {
    version: process.env.VERSION,
    language,
    token,
    trafficSource,
  })
  return payload
}

export const createOnboardingData = async (type) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/create-onboarding-data?type=${type}`)
  return payload
}


export const removeOnboardingData = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/delete-onboarding-data`)
  return payload
}


// Profile
export const updateProfile = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/profiles/me`, 'PATCH', data)
  tracker.logEvent('UPDATE_PROFILE')

  return normalize(payload, profileSchema)
}

export const requestProfile = async () => {
  const token = await getToken()
  try {
    const payload = await connector.request(token, `${API_ROOT}/profiles/me`)
    return normalize(payload, profileSchema)
  } catch (err) {
    if (err.status === 404) {
      await initializeUser()
      return requestProfile()
    }
    throw err
  }
}

export const requestEmailVerification = async (email) => {
  const token = await getToken()
  await connector.request(token, `${API_ROOT}/profiles/me/request-verification`, 'POST', { email })
}

export const verifyEmail = async (token) => {
  await connector.request(null, `${API_ROOT}/verify-email?token=${token}`, 'GET')
}

export const deleteAccount = async () => {
  const token = await getToken()
  await connector.request(token, `${API_ROOT}/delete-account`, 'POST')
}

// File import
export const getImportPreview = async (fileId) => {
  const token = await getToken()
  tracker.logEvent('IMPORT_FILE')
  const payload = await connector.request(token, `${API_ROOT}/import`, 'POST', { fileId })
  return payload
}

export const importData = async (data) => {
  const token = await getToken()
  tracker.logEvent('IMPORT_DATA')
  const payload = await connector.request(token, `${API_ROOT}/import-data`, 'POST', data)
  return payload
}

// Subscriptiions
export const subscribe = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/subscriptions`, 'POST', data)
  return payload
}

export const unsubscribe = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/subscriptions/current`, 'DELETE')
  tracker.logEvent('UNSUBSCRIBE')

  return payload
}

export const requestSubscriptions = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/subscriptions`)

  return normalize(payload, [subscriptionSchema])
}

export const requestSubscriptionPlans = async (countryCode) => {
  const query = {}
  if (countryCode) query.countryCode = countryCode
  const payload = await connector.request(null, `${API_ROOT}/plans?${getQueryString(query)}`)
  return normalize(payload, [subscriptionPlanSchema])
}

export const requestPaymentData = async () => {
  const token = await getToken()
  try {
    const payload = await connector.request(token, `${API_ROOT}/payment-details`)
    return payload
  } catch (error) {
    return null
  }
}

export const requestPaymentInfo = async (plan, voucher) => {
  const token = await getToken()
  const user = await getUser()
  const queries = {
    planId: plan.id,
    email: user.username,
    voucherId: get(voucher, 'id'),
  }
  return connector.request(token, `${API_ROOT}/subscriptions/payment-info?${getQueryString(queries)}`)
}

export const finalizePayment = async (data) => {
  const token = await getToken()
  return connector.request(token, `${API_ROOT}/subscriptions/finalize`, 'POST', data)
}

export const checkVoucher = async (code) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/check-voucher?code=${code}`)
  return normalize(payload, voucherSchema)
}


// Logging
export const logEvent = async (data) => {
  const token = await getToken()
  await connector.request(token, `${API_ROOT}/log`, 'POST', data)
}

// Release Note
export const requestReleaseNote = async (languageCode) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/release-note/${languageCode}`)
  return payload
}

// Net Promoter Score
export const requestNps = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/net-promoter-score`)
  return normalize(payload, [npsSchema])
}
export const createNps = async () => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/net-promoter-score`, 'POST', {})
  return normalize(payload, npsSchema)
}

export const updateNps = async (data) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/net-promoter-score/${data.id}`, 'PATCH', data)
  return normalize(payload, npsSchema)
}

export const sendInvitationEmails = async (emails) => {
  const token = await getToken()
  const payload = await connector.request(token, `${API_ROOT}/send-invitation-emails`, 'POST', { emails })
  return normalize(payload, npsSchema)
}

export const sendFeedbackEmail = async (profile, currentNps) => {
  const token = await getToken()
  const user = await getUser()
  const payload = await connector.request(token, `${API_ROOT}/send-feedback-email`, 'POST', { profile, currentNps, username: user.username })
  return normalize(payload, npsSchema)
}

export const ping = async () => {
  // use /plans endpoint for checking if the user is online
  const token = await getToken()
  await connector.request(token, `${API_ROOT}/profiles/me/ping`, 'GET', null, false)
}


// Survey
export const createSurveyResponse = async (values) => {
  const token = await getToken()
  await connector.request(token, `${API_ROOT}/survey/platform/responses`, 'POST', { ...values })
}


// Reference collections - testing api request abstraction

export const requestReferenceCollections = () => apiRequest(Endpoints.referenceCollection(), { schema: [referenceCollectionSchema] })

export const createReferenceCollection = data => apiRequest(Endpoints.referenceCollection(), {
  method: 'POST',
  data,
  tracking: { event: 'CREATE_REF_COLLECTION', data: payload => ({ ...data, id: payload.id }) },
  schema: referenceCollectionSchema,
})

export const deleteReferenceCollection = id => apiRequest(Endpoints.referenceCollection(id), {
  method: 'DELETE',
  tracking: { event: 'DELETE_REF_COLLECTION', data: { id } },
})

export const updateReferenceCollection = data => apiRequest(Endpoints.referenceCollection(data.id), {
  method: 'PATCH',
  data,
  tracking: { event: 'UPDATE_REF_COLLECTION', data: { id: data.id } },
  schema: referenceCollectionSchema,
})


// challenge

export const requestScores = () => apiRequest(Endpoints.scoreBoard(), { schema: [scoreSchema], isPublic: true })

export const requestEarlyBirdStatus = () => apiRequest(Endpoints.earlyBird(), { method: 'GET' })


// Math
export const requestSvg = tex => apiRequest(Endpoints.formula(), { data: { tex }, method: 'POST' })

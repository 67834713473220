import {
  get, values, isArray, isObject, compact, flatten, find,
} from 'lodash'
import { createSelector } from 'reselect'
import i18n from 'i18n'
import { getSelectedReferenceIdLibrary } from 'selectors/path'

import { roleFields } from 'helper/formHelper'
import { getSelectedCollection, getEntities } from 'selectors/reference-collections'
import { sortReferencesBy } from 'helper/utils'
import { ReferenceSortAttribute } from 'helper/constants'

export const ReferenceLibraries = {
  PERSONAL: 'lib',
  CROSSREF: 'crossref',
  GOOGLE_BOOKS: 'google-books',
  PUBMED: 'pubmed',
}
export const EXTERNAL_LIBRARIES = [ReferenceLibraries.CROSSREF, ReferenceLibraries.GOOGLE_BOOKS, ReferenceLibraries.PUBMED]


export const selector = state => state.entities.references
export const selectorNotes = state => state.entities.notes || []
export const librariesSelector = state => state.reference.libraries
export const getOrderBy = state => state.reference.orderBy
export const getOrder = state => state.reference.order
const profileSelector = state => get(state, 'entities.profiles[0]')

export const getAll = createSelector(
  selector,
  references => values(references),
)

export const getById = (state, id) => get(selector(state), `['${id}']`)

export const getCsl = state => state.reference.csl

export const hasInitialReferences = createSelector(
  selector,
  refs => values(refs).filter(ref => ref.isInitial).length > 0,
)

export const getCount = createSelector(
  selector,
  references => values(references).length,
)

export const getNoneInitialCount = createSelector(
  selector,
  references => values(references).filter(reference => !reference.isInitial).length,
)

export const getReferenceFromExternalReference = externalReference => createSelector(
  getAll,
  references => find(
    references,
    reference => (reference.DOI && externalReference.DOI && reference.DOI === externalReference.DOI)
     || (reference.ISBN && externalReference.ISBN && reference.ISBN === externalReference.ISBN)
     || (reference.PMID && externalReference.PMID && reference.PMID === externalReference.PMID)
     || (reference.ISSN && externalReference.ISSN && reference.ISSN === externalReference.ISSN)
     || (reference.externalId && externalReference.externalId && reference.externalId === externalReference.externalId),
  ),
)

export const getIdentifiers = createSelector(
  getAll,
  references => new Set(flatten(
    references.map(reference => compact([reference.DOI, reference.ISBN, reference.PMID, reference.ISSN, reference.externalId])),
  )),
)

export const wasAlreadyAdded = reference => createSelector(
  getIdentifiers,
  identifiers => (reference
    ? identifiers.has(reference.DOI)
    || identifiers.has(reference.ISBN)
    || identifiers.has(reference.PMID)
    || identifiers.has(reference.ISSN)
    || identifiers.has(reference.externalId)
    : false
  ),
)

export const getReferenceFromPath = createSelector(
  getSelectedReferenceIdLibrary,
  selector,
  librariesSelector,
  getEntities,
  ({ id, library }, references, libraries, collections) => {
    if (library === ReferenceLibraries.PERSONAL) {
      const reference = references[id]
      if (!reference) return null
      return {
        ...reference,
        collections: collections && reference.collections && compact(reference.collections.map(collectionId => collections[collectionId])),
      }
    }
    return get(libraries, [library, 'searchResult', 'entities', 'externalReference', id])
  },
)

export const getIsExternalLib = createSelector(
  getSelectedReferenceIdLibrary,
  ({ library }) => library !== 'lib',
)


export const getReferencedNotes = createSelector(
  getSelectedReferenceIdLibrary,
  selectorNotes,
  ({ id, library }, notes) => {
    if (library === ReferenceLibraries.PERSONAL) {
      return values(notes).filter(note => note.referenceIds && note.referenceIds.includes(id))
    }
    return []
  },
)

export const getValidRoles = createSelector(
  getReferenceFromPath,
  (reference) => {
    if (!reference) return []
    const type = reference.type || 'article'
    const translationKeys = Object.keys(i18n.t(`references:types.${type}`, { returnObjects: true }))
    return roleFields.filter(role => translationKeys.includes(role))
  },
)


// Types / Form

export const getReferenceTypes = createSelector(
  profileSelector,
  () => {
    const types = Object.keys(i18n.t('references:types', { returnObjects: true }))
    types.sort((a, b) => {
      const aValue = i18n.t(`references:types.${a}.name`)
      const bValue = i18n.t(`references:types.${b}.name`)
      return (aValue).localeCompare(bValue)
    })
    return types
  },
)

// Search
export const getSearchQuery = state => state.reference.query

export const isSearching = createSelector(
  librariesSelector,
  libraries => values(libraries).reduce((acc, lib) => acc || lib.state === 'LOADING', false),
)

export const getLibrarySearchStates = createSelector(
  librariesSelector,
  (libraries) => {
    const result = {}
    Object.keys(libraries).forEach((key) => {
      const lib = libraries[key]
      if (lib.state === 'SUCCESS') result[key] = lib.searchResult.totalCount || lib.searchResult.result.length
      else result[key] = lib.state
    })
    return result
  },
)

const match = (obj, query) => values(obj).reduce((acc, value) => {
  let foundMatch = false
  if (isArray(value) || isObject(value)) {
    foundMatch = match(value, query)
  } else {
    foundMatch = (value !== undefined && JSON.stringify(value).toLowerCase().includes(query.toLowerCase()))
  }
  return acc || foundMatch
}, false)

export const getFilteredBySearchQuery = createSelector(
  selector,
  getSearchQuery,
  (references, query) => {
    if (!query || query === '') return values(references)

    return values(references).filter(reference => match(reference, query))
  },
)

export const getFiltered = createSelector(
  getFilteredBySearchQuery,
  getSelectedCollection,
  (references, collection) => {
    if (!collection) return references
    return references.filter(reference => (reference.collections ? reference.collections.includes(collection.id) : false))
  },
)

export const getSortedFiltered = createSelector(
  getFiltered,
  getOrderBy,
  getOrder,
  (filteredReferences, orderBy, order) => sortReferencesBy(filteredReferences, orderBy, order),
)

const libSelector = libraryKey => state => get(state, ['reference', 'libraries', libraryKey, 'searchResult', 'entities', 'externalReference'], {})

export const getLibraryReferences = libraryKey => createSelector(
  libSelector(libraryKey),
  references => values(references),
)

export default {
  getAll,
  getFilteredBySearchQuery,
  getFiltered,
  getById,
  getRaw: state => selector(state),
  getCsl,
  getCount,
  hasInitialReferences,
  getReferencedNotes,
  getReferenceTypes,
  getSearchQuery,
  isSearching,
  getLibrarySearchStates,
  getOrder,
  getOrderBy,
  getSortedFiltered,
  getNoneInitialCount,
}

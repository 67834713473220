import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { propTypes as i18nPropTypes, translate } from 'i18n'

import { parseColor } from 'helper/colorHandler'
import { AuratikumFontIcons } from 'helper/utils'
import { OutlinedButton, OutlinedSecondaryButton } from 'components/common/Button'
import Icon from 'components/common/Icon'
import modifyClassName from 'helper/modifyClassName'
import Footnote from 'components/common/Footnote'
import ReferenceCitation from 'components/common/ReferenceCitation'

import { CitationStyleType, updateDomCitations } from 'helper/citation'
import WritingMovable from '../WritingMovable'

import './style.less'

class WritingNote extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      citationCluster: PropTypes.shape({
        citations: PropTypes.array,
        type: PropTypes.string,
      }),
    }),
    onEditClick: PropTypes.func,
    onSelectForMoveAndDrop: PropTypes.func,
    removeProjectItem: PropTypes.func,
    movingModeActive: PropTypes.bool,
    ...i18nPropTypes,
  }

  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    this.rerenderCitations()
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.citationCluster !== prevProps.item.citationCluster) {
      this.rerenderCitations()
    }
  }


  handleEditClick = () => this.props.onEditClick(this.props.item)

  handleRemove = () => this.props.removeProjectItem(this.props.item)

  rerenderCitations() {
    const el = this.contentRef.current
    if (el) {
      const citationEls = [...el.querySelectorAll('.IntextCitation')]
      updateDomCitations(citationEls, this.props.item.citationCluster.citations, this.props.item.citationCluster.type)
    }
  }

  renderUncitedReference(reference) {
    if (!reference) return null
    return (
      <div className="WritingNote__Reference" key={reference.id}>
        <div className="WritingNote__ReferenceIcon"><Icon icon={AuratikumFontIcons.REFERENCE} /></div>
        <ReferenceCitation className="WritingNote__ReferenceContent" reference={reference} />
      </div>
    )
  }

  render() {
    const { item, movingModeActive, t } = this.props
    return (
      <div id={`WritingItem-${item.id}`} className="WritingNote">
        <div className="WritingNote__Note" style={{ borderLeftColor: item.note && parseColor(item.note.color) }}>
          <div className="WritingNote__NoteHeader">{item.note && item.note.title}</div>
          <div className="WritingNote__NoteContent">
            <div dangerouslySetInnerHTML={{ __html: item.note && item.note.content }} ref={this.contentRef} />
          </div>
          { item.citationCluster.type === CitationStyleType.FOOTNOTE && get(item, 'citationCluster.citations', []).map(citation => <Footnote key={citation.citationId} citation={citation} />) }
          { item.note && item.note.referenceIds && item.note.referenceIds.length > 0 && (
            <div className="WritingNote__UncitedRefContainer">
              <div className="WritingNote__UncitedRefTitle">{t('writing.uncitedReferencesTitle')}</div>
              { item.note.references.map(reference => this.renderUncitedReference(reference))}
            </div>
          )}
        </div>
        <div className={modifyClassName('WritingNote__Actions', { hidden: movingModeActive })}>
          <OutlinedSecondaryButton className="WritingNote__Action" onClick={this.props.onSelectForMoveAndDrop} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.MOVE} /></OutlinedSecondaryButton>
          <OutlinedButton className="WritingNote__Action" onClick={this.handleEditClick} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.EDITLINES} /></OutlinedButton>
          <OutlinedButton className="WritingNote__Action" onClick={this.handleRemove} circleModifier="circleSmall"><Icon icon={AuratikumFontIcons.DELETE} /></OutlinedButton>
        </div>
      </div>
    )
  }
}

export default WritingMovable(translate()(WritingNote))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, propTypes as reduxPropTypes } from 'redux-form'
import { propTypes as i18nPropTypes, translate } from 'i18n'
import { changeLanguage } from 'actions/i18n'
import { push } from 'connected-react-router'
import { showModal, hideModal } from 'actions/modal'

import { backup } from 'api'
import {
  requestProfile, updateProfile, removeOnboardingData,
} from 'actions/profiles'
import { doLogout } from 'actions/authentication'
import ProfileSelector from 'selectors/profile'
import SubscriptionSelector from 'selectors/subscriptions'
import FormTextInput from 'components/common/FormTextInput'
import FormLanguageSelectorInput from 'components/common/FormLanguageSelectorInput'
import { PrimaryButton, OutlinedButton } from 'components/common/Button'
import StyleSelectorField from 'components/StyleSelectorField'
import FeatureFlag from 'containers/FeatureFlag'
import EducationVerificationContainer from 'containers/profile/EducationVerificationContainer'
import { InfoBigToast } from 'containers/toasts/BigToast'
import FontIcon from 'components/common/FontIcon'
import DeleteAccountModal, { MODAL_ID as DELETE_ACCOUNT_MODAL_ID } from 'containers/modal/DeleteAccountModal'


import './style.less'

const CITATION_STYLE_FAILURE_TOAST_ID = 'CITATION_STYLE_FAILURE_TOAST_ID'

class ProfileContainer extends Component {
  constructor() {
    super()

    this.state = {
      loadingBackup: false,
      loadingOnboardDelete: false,
    }
  }

  submit = async (data) => {
    try {
      await this.props.updateProfile(data)
    } catch (e) {
      this.props.showModal(CITATION_STYLE_FAILURE_TOAST_ID, { content: this.props.t('profile.citationStyleError'), data: { level: 'danger' } })
      this.props.reset()
    }
    await this.props.changeLanguage(data.language)
  }

  backup = async () => {
    this.setState({ loadingBackup: true })
    await backup('aura')
    this.setState({ loadingBackup: false })
  }

  removeOnboardingData = async () => {
    this.setState({ loadingOnboardDelete: true })
    await this.props.removeOnboardingData()
    this.setState({ loadingOnboardDelete: false })
  }

  doLogout = async () => {
    this.props.doLogout()
    if (window && window.Intercom) {
      window.Intercom('shutdown')
      window.Intercom('boot', { app_id: 'dcyqa8l9' })
    }
  }

  handleDeleteAccount = () => {
    this.props.showModal(DELETE_ACCOUNT_MODAL_ID)
  }

  hideModal = () => {
    this.props.hideModal(CITATION_STYLE_FAILURE_TOAST_ID)
  }

  renderPersonal = () => {
    const { handleSubmit, submitting, t } = this.props

    return (
      <div className="ProfileContainer__Box">
        <div className="ProfileContainer__Heading">{t('profile.personal.title')}</div>
        <form onSubmit={handleSubmit(this.submit)} className="ProfileContainer__Form">
          <Field name="firstName" label={t('common.firstName')} component={FormTextInput} />
          <Field name="lastName" label={t('common.lastName')} component={FormTextInput} />
          <Field name="citationStyle2" label={t('common.citationStyle')} component={StyleSelectorField} onChange={this.hideModal} />
          <Field name="language" label={t('common.language')} component={FormLanguageSelectorInput} />
          <PrimaryButton className="ProfileContainer__SubmitButton" type="submit" isLoading={submitting}>{t('common.save')}</PrimaryButton>
        </form>
      </div>
    )
  }

  renderRemoveButton = () => {
    const { t } = this.props

    return (
      <div className="ProfileContainer__Section">
        <div className="ProfileContainer__Heading">{t('profile.initial.title')}</div>
        <div className="ProfileContainer__Text">{t('profile.initial.text')}</div>
        <PrimaryButton className="ProfileContainer__LastButton" onClick={this.props.removeOnboardingData} isLoading={this.state.loadingOnboardDelete}>{t('profile.initial.delete')}</PrimaryButton>
      </div>
    )
  }

  renderAdditional = () => {
    const { t } = this.props

    return (
      <div className="ProfileContainer__Box">
        <FeatureFlag feature="payment">
          <div className="ProfileContainer__Section">
            <div className="ProfileContainer__Heading">{t('profile.education')}</div>
            <EducationVerificationContainer />
          </div>
        </FeatureFlag>
        <FeatureFlag feature="payment">
          { !this.props.hasSubscription
            && (
              <div className="ProfileContainer__Section">
                <div className="ProfileContainer__Heading">{t('profile.affiliate.title')}</div>
                <div className="ProfileContainer__Text ProfileContainer__Invite">
                  {t('profile.affiliate.text')}
                  <br />
                  <OutlinedButton className="ProfileContainer__Limit ProfileContainer__LastButton" onClick={() => this.props.push('/profile/referral')}>
                    {t('profile.affiliate.button')}
                  </OutlinedButton>
                </div>
              </div>
            )
          }
        </FeatureFlag>
        <div className="ProfileContainer__Section">
          <div className="ProfileContainer__Heading">{t('profile.backup.title')}</div>
          <div className="ProfileContainer__Text">{t('profile.backup.text')}</div>
          <OutlinedButton className="ProfileContainer__LastButton ProfileContainer__Limit" onClick={() => this.backup()} isLoading={this.state.loadingBackup}>{t('profile.backup.download')}</OutlinedButton>
        </div>

        { this.props.hasInitialData && this.renderRemoveButton() }

        <div className="ProfileContainer__Section">
          <div className="ProfileContainer__Heading">{t('profile.account')}</div>
          <OutlinedButton className="ProfileContainer__Limit" onClick={this.doLogout}>{this.props.t('common.logout')}</OutlinedButton>
          <div className="ProfileContainer__DeleteAccount">
            <FontIcon icon={FontIcon.Icons.faExclamationTriangle} />
            <button type="button" className="" onClick={this.handleDeleteAccount}>{this.props.t('profile.deleteAccount')}</button>
          </div>

        </div>
        <div className="ProfileContainer__Version">
          Version
          {' '}
          {process.env.VERSION_LABEL}
        </div>
      </div>
    )
  }

  render = () => (
    <div className="ProfileContainer">
      { this.renderPersonal() }
      { this.renderAdditional() }
      <InfoBigToast
        id={CITATION_STYLE_FAILURE_TOAST_ID}
        timeout={10000}
      />
      <DeleteAccountModal id={DELETE_ACCOUNT_MODAL_ID} />
    </div>
  )
}

const form = { form: 'Profile', enableReinitialize: true }

ProfileContainer.propTypes = {
  updateProfile: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  doLogout: PropTypes.func,
  removeOnboardingData: PropTypes.func,
  hasSubscription: PropTypes.bool,
  changeLanguage: PropTypes.func,
  ...reduxPropTypes,
  ...i18nPropTypes,
}

const mapStateToProps = state => ({
  initialValues: ProfileSelector.getProfile(state),
  hasSubscription: SubscriptionSelector.hasSubscription(state),
  inviteLink: ProfileSelector.getInviteLink(state),
  hasInitialData: ProfileSelector.hasInitialData(state),
})

export default connect(mapStateToProps, {
  requestProfile,
  updateProfile,
  doLogout,
  removeOnboardingData,
  changeLanguage,
  push,
  showModal,
  hideModal,
})(reduxForm(form)(translate()(ProfileContainer)))

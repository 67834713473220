import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNumber } from 'lodash'

import { propTypes as i18nPropTypes, translate } from 'i18n'
import modifyClassName from 'helper/modifyClassName'
import ReferenceSelector, { EXTERNAL_LIBRARIES, ReferenceLibraries } from 'selectors/references'

import Spinner from 'components/common/Spinner'
import FontIcon from 'components/common/FontIcon'
import logoGoogleBooks from 'assets/logoGoogleBooks.png'

import './style.less'


const LIB_ICONS = {
  [ReferenceLibraries.CROSSREF]: 'https://assets.crossref.org/logo/crossref-logo-landscape-200.svg',
  [ReferenceLibraries.PUBMED]: 'https://www.nlm.nih.gov/about/logos_nlm_photos/pubmed_logo.png',
  [ReferenceLibraries.GOOGLE_BOOKS]: logoGoogleBooks,
}


class LibrarySelector extends Component {
  static propTypes = {
    ...i18nPropTypes,
    className: PropTypes.string,
    selectedValue: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    useLogo: PropTypes.bool,
    autoGrow: PropTypes.bool,
  }

  renderTab = (libraryKey, state) => {
    const {
      selectedValue, onSelect, showIndicator, t, useLogo, autoGrow,
    } = this.props

    return (
      <button
        type="button"
        className={modifyClassName('LibrarySelector__Tab', { active: libraryKey === selectedValue, 'auto-grow': autoGrow })}
        key={libraryKey}
        onClick={() => onSelect(libraryKey)}
      >
        <div className="LibrarySelector__TabLabel">
          {useLogo && LIB_ICONS[libraryKey] ? <img className="LibrarySelector__Logo" src={LIB_ICONS[libraryKey]} alt="Logo" /> : t(`references.libraries.${libraryKey}.name`)}
        </div>
        <div className="LibrarySelector__IndicatorWrapper">
          { (showIndicator || libraryKey === 'lib') && (
          <div className={modifyClassName('LibrarySelector__Indicator', { error: state === 'ERROR' })}>
            <Spinner show={state === 'LOADING'}>
              { state === 'ERROR' && <FontIcon icon={FontIcon.Icons.faExclamationCircle} /> }
              { isNumber(state) && state }
            </Spinner>
          </div>
          )}
        </div>
      </button>
    )
  }

  render() {
    const { libraryStates, className, userLibReferenceCount } = this.props

    return (
      <div className={`LibrarySelector ${className || ''}`}>
        { this.renderTab('lib', userLibReferenceCount) }
        { libraryStates && EXTERNAL_LIBRARIES.map(libraryKey => this.renderTab(libraryKey, libraryStates[libraryKey])) }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const query = ReferenceSelector.getSearchQuery(state)
  return {
    libraryStates: ReferenceSelector.getLibrarySearchStates(state),
    userLibReferenceCount: ReferenceSelector.getFilteredBySearchQuery(state).length,
    showIndicator: query && query.length > 0,
  }
}

export const PlainLibrarySelector = translate()(LibrarySelector)

export default connect(mapStateToProps)(PlainLibrarySelector)
